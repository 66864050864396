import { FC, useMemo, useState } from 'react';
import { IClub, IGroup, IUser, Role, Sport } from '@hulanbv/toftennis';
import { InputElement } from '../elements/input-element/input-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { SelectElement } from '../elements/select-element/select-element.component';
import { useSettingsContext } from '../../domain/settings/use-settings-context.hook';
import { sportIcons } from '../../domain/common/constants/sport-icons.constant';
import { sportLabels } from '../../domain/common/constants/sport-labels.constant';
import { phaseColorLabels } from '../../domain/phases/phase-color-labels.constant';

type Props = {
  model?: IGroup;
  users: IUser[];
  clubs: IClub[];
  onSubmit: (formData: FormData) => void | Promise<void>;
  onCancel: () => void | Promise<void>;
};

const GroupFormTemplate: FC<Props> = (props) => {
  const { activeSports } = useSettingsContext();
  const [selectedSport, setSelectedSport] = useState<Sport | null>(
    props.model?.sport ?? activeSports[0] ?? null,
  );
  const players = useMemo(
    () => props.users.filter((user) => user.role === Role.PLAYER),
    [props.users],
  );
  const trainers = useMemo(
    () => props.users.filter((user) => user.role === Role.INSTRUCTOR),
    [props.users],
  );
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        props.onSubmit(formData);
      }}
    >
      <FlexElement gap={30}>
        {props.model?.id && (
          <input type="hidden" name="_id" value={props.model?.id ?? ''} />
        )}
        <FlexElement>
          <InputElement
            attributes={{
              placeholder: 'Naam',
              required: true,
              name: 'name',
              defaultValue: props.model?.name,
            }}
            hasDarkText
          />
          {props.clubs.length === 1 && (
            <input type="hidden" name="clubId" value={props.clubs[0]?.id} />
          )}

          {props.clubs.length > 1 && (
            <SelectElement
              options={props.clubs.map((club) => [club.id ?? '', club.name])}
              placeholder="Selecteer club"
              name="clubId"
              hasDarkText
              required
            />
          )}

          <FlexElement direction="row">
            <SelectElement
              options={activeSports.map((sport) => {
                const Icon = sportIcons[sport];
                return [
                  sport.toString(),
                  <FlexElement direction="row">
                    <Icon height={'1em'} /> {sportLabels[sport]}
                  </FlexElement>,
                ];
              })}
              placeholder="Selecteer sport"
              name="sport"
              defaultValue={([] as string[]).concat(
                selectedSport?.toString() ?? [],
              )}
              onChange={([sport]) =>
                setSelectedSport(sport !== undefined ? (+sport as Sport) : null)
              }
              hasDarkText
              required
            />
            <SelectElement
              options={
                selectedSport !== null
                  ? Object.entries(phaseColorLabels[selectedSport]).map(
                      ([color, label]) => [color, label],
                    )
                  : []
              }
              placeholder="Kies kaart"
              name="color"
              defaultValue={([] as string[]).concat(
                props.model?.color?.toString() ?? [],
              )}
              isDisabled={selectedSport === null}
              hasDarkText
              required
            />
          </FlexElement>
        </FlexElement>
        <FlexElement alignItems="start" gap={10}>
          Trainers
          <SelectElement
            options={trainers.map(
              ({ id, firstName, lastName, preposition }) => [
                id ?? '',
                `${firstName} ${preposition ?? ''} ${lastName}`,
              ],
            )}
            placeholder="Selecteer trainers"
            multiselect
            defaultValue={props.model?.instructorIds}
            name="instructorIds"
            hasDarkText
            required
          />
        </FlexElement>

        <FlexElement alignItems="start" gap={10}>
          Leerlingen
          <SelectElement
            options={players.map(({ id, firstName, lastName, preposition }) => [
              id ?? '',
              `${firstName} ${preposition ?? ''} ${lastName}`,
            ])}
            placeholder="Selecteer spelers"
            defaultValue={props.model?.memberIds}
            name="memberIds"
            hasDarkText
            multiselect
            required
          />
        </FlexElement>

        <FlexElement direction="row">
          <ButtonElement
            flavour="ghost"
            attributes={{ type: 'button', onClick: props.onCancel }}
          >
            Annuleren
          </ButtonElement>
          <ButtonElement
            flavour="blue"
            attributes={{ type: 'submit', disabled: selectedSport === null }}
          >
            Opslaan
          </ButtonElement>
        </FlexElement>
      </FlexElement>
    </form>
  );
};

export { GroupFormTemplate };
