import { Notification } from '../../../domain/common/hooks/notification/use-notification.hook';
import { FlexElement } from '../flex-element/flex-element.component';
import { NotificationElement } from '../notification-element/notification-element.component';
import styles from './notification-center-element.module.css';

type Props = {
  notifications: Notification[];
};

function NotificationCenterElement(props: Props): JSX.Element {
  return (
    <FlexElement attributes={{ className: styles.notificationCenter }}>
      {props.notifications.map((notification, i) => (
        <NotificationElement key={i} type={notification.type}>
          {notification.content}
        </NotificationElement>
      ))}
    </FlexElement>
  );
}

export { NotificationCenterElement };
