import { ICredentials, ISession } from '@hulanbv/toftennis';
import { IHttpOptions, IResponse } from 'nest-utilities-client';
import { useCallback, useEffect, useState } from 'react';
import { authenticationService } from './authentication.service';

type UseAuthResponse = {
  login: (
    credentials: ICredentials | FormData,
    options?: IHttpOptions,
  ) => Promise<IResponse<ISession, null>>;
  logout: (options?: IHttpOptions) => Promise<IResponse<void, null>>;
  validate: (options?: IHttpOptions) => Promise<IResponse<ISession, null>>;
  sessionToken: ISession | null;
};

/**
 * Authenticates with the API returning a session token
 * @returns
 */
function useAuth(): UseAuthResponse {
  const [sessionToken, setSessionToken] = useState<ISession | null>(null);

  const login = useCallback(async (credentials: ICredentials | FormData) => {
    const response = await authenticationService.login(credentials, {
      populate: ['user'],
    });
    setSessionToken(response.data);

    return response;
  }, []);

  const logout = useCallback(async () => {
    const response = await authenticationService.logout();
    setSessionToken(null);

    return response;
  }, []);

  const validate = useCallback(async () => {
    try {
      const response = await authenticationService.validate();
      setSessionToken(response.data);

      return response;
    } catch (error) {
      await logout();
      throw error;
    }
  }, [logout]);

  /**
   * This effect checks for a authorization query parameter which could contain
   * the auth token for the user. If present, it store uses the token to validate
   * the users session.
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorization = urlParams.get('authorization');
    if (authorization) {
      authenticationService.storeSession({
        token: authorization,
        userId: '',
        validatedAt: new Date(),
      });
    }
  }, []);

  return {
    login,
    logout,
    validate,
    sessionToken,
  };
}

export { useAuth };
export type { UseAuthResponse };
