import { Sport } from '@hulanbv/toftennis';
import { useCallback, useState } from 'react';
import { settingsService } from './settings.service';

type UseSettingsResponse = {
  activeSports: Sport[];
  saveActiveSports: (activeSports: Sport[]) => void;
};

function useSettings(): UseSettingsResponse {
  const [activeSports, setActiveSports] = useState<Sport[]>(
    settingsService.activeSports,
  );

  const saveActiveSports = useCallback((activeSports: Sport[]) => {
    settingsService.activeSports = activeSports;
    setActiveSports(activeSports);
  }, []);

  return { activeSports, saveActiveSports };
}

export { useSettings };
export type { UseSettingsResponse };
