import { useContext } from 'react';
import { AuthContext } from './auth.context';
import { useAuth } from './use-auth.hook';

/**
 * Returns a context aware useAuth function
 * @returns
 */
function useAuthContext(): ReturnType<typeof useAuth> {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
}

export { useAuthContext };
