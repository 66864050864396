import { useCallback, useState } from 'react';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { InputElement } from '../elements/input-element/input-element.component';
import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';

type Props = {
  confirmButtonText?: string;
  cancelButtonText?: string;
  resolve: (score: number) => void;
};

function AddEventScoreModal(props: Props): JSX.Element {
  const { closeModal } = useModalContext();

  const [isDisabled, setIsDisabled] = useState(false);
  const [score, setScore] = useState<number>(0);

  const handleOnConfirm = useCallback(async () => {
    setIsDisabled(true);
    try {
      await props.resolve(score);
    } finally {
      setIsDisabled(false);
      closeModal();
    }
  }, [closeModal, props, score]);

  return (
    <FlexElement>
      <p>Vul de TOF score in</p>

      <FlexElement
        direction="row"
        attributes={{
          style: {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <InputElement
          hasDarkText
          attributes={{
            style: { flex: 1 },
            type: 'number',
            name: 'score',
            value: score || '',
            placeholder: 'Score',
            required: true,
            min: 1,
            onChange: (event) => setScore(Number(event.target.value)),
            onFocus: (event) => score === 0 && event.currentTarget.select(),
          }}
        />
      </FlexElement>

      <FlexElement direction="row">
        <ButtonElement
          flavour="ghost"
          attributes={{
            onClick: closeModal,
          }}
        >
          Annuleren
        </ButtonElement>
        <ButtonElement
          flavour="blue"
          attributes={{
            onClick: handleOnConfirm,
            disabled: isDisabled,
          }}
        >
          Opslaan
        </ButtonElement>
      </FlexElement>
    </FlexElement>
  );
}

export { AddEventScoreModal };
