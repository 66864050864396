import { IPhase } from '@hulanbv/toftennis';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<IPhase> {
  constructor() {
    super([process.env.REACT_APP_API_URL, 'phases'].join('/'), httpService);
  }
}

const phaseService = new Service();

export { phaseService };
