import { IGroup } from '@hulanbv/toftennis';
import { IHttpOptions } from 'nest-utilities-client';
import { useCallback, useEffect, useState } from 'react';
import { groupService } from '../group.service';

type UseGroupsHook = {
  groups: IGroup[] | null;
  refresh: () => Promise<void>;
};

function useGroups(options?: IHttpOptions): UseGroupsHook {
  const [groups, setGroups] = useState<IGroup[] | null>(null);

  const fetchGroups = useCallback(async () => {
    const { data } = await groupService.getAll(options);
    setGroups(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- check for stringyfied changes
  }, [JSON.stringify(options)]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return { groups, refresh: fetchGroups };
}

export { useGroups };
