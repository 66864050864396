const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
};
const intl = new Intl.DateTimeFormat('nl-NL', options);

const formatDate = (date?: Date | string | number): string => {
  if (!date) {
    return '-';
  }

  if (date instanceof Date) {
    return intl.format(date);
  }

  return intl.format(new Date(date));
};

export { formatDate };
