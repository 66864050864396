import { HttpService, IResponse } from 'nest-utilities-client';
import { useAuth } from '../authentication/use-auth.hook';

class Service extends HttpService {
  public authContext: ReturnType<typeof useAuth> | null = null;

  async getHeaders() {
    const { authenticationService } = await import(
      '../authentication/authentication.service'
    );

    return {
      authorization: authenticationService.getSession()?.token ?? '',
    };
  }

  async onRequestError(error: IResponse<Error>) {
    // clear the session token if its invalid
    if (error.status === 401) {
      this.authContext?.logout();
    }
  }
}

const httpService = new Service();

export { httpService };
