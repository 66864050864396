import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './button-element.module.css';

export type ButtonProps = PropsWithChildren<{
  attributes?: ButtonHTMLAttributes<HTMLButtonElement>;
  flavour?:
    | 'white'
    | 'blue'
    | 'ghost'
    | 'dark-blue'
    | 'orange'
    | 'danger'
    | 'text';
  fitContent?: boolean;
  size?: 'small' | 'medium';
}>;

function ButtonElement(props: ButtonProps): JSX.Element {
  return (
    <button
      {...{
        ...props.attributes,
        className: join(
          props.attributes?.className,
          styles.button,
          styles[`${props.flavour ?? 'white'}-flavour`],
          styles[`${props.size ?? 'medium'}-size`],
          props.fitContent && styles.fitContent,
        ),
      }}
    >
      {props.children}
    </button>
  );
}

export { ButtonElement };
