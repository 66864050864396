import { ReactNode } from 'react';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';

type Props = {
  content: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  resolve: (value: boolean) => void;
};

function ConfirmModalTemplate(props: Props): JSX.Element {
  return (
    <FlexElement>
      {props.content}
      <FlexElement direction="row">
        <ButtonElement
          flavour="ghost"
          attributes={{
            onClick: () => props.resolve(false),
          }}
        >
          {props.cancelButtonText ?? 'Nee'}
        </ButtonElement>
        <ButtonElement
          flavour="blue"
          attributes={{
            onClick: () => props.resolve(true),
          }}
        >
          {props.confirmButtonText ?? 'Ja'}
        </ButtonElement>
      </FlexElement>
    </FlexElement>
  );
}

export { ConfirmModalTemplate };
