import { CSSProperties, FC, useMemo } from 'react';
import styles from './video-player-element.module.css';

type Props = {
  url: string;
  width?: CSSProperties['width'];
};

type UrlConversion = {
  hostnames: string[];
  convert: (url: URL) => string;
};

const urlConversions: UrlConversion[] = [
  {
    hostnames: ['vimeo.com'],
    convert: (url: URL) =>
      `https://player.vimeo.com/video/${url.pathname.split('/')[1]}?h=${
        url.pathname.split('/')[2] ?? ''
      }`,
  },
  {
    hostnames: ['youtube.com', 'www.youtube.com'],
    convert: (url: URL) =>
      `https://www.youtube.com/embed/${url.searchParams.get('v')}`,
  },
  {
    hostnames: ['youtu.be'],
    convert: (url: URL) =>
      `https://www.youtube.com/embed/${url.pathname.split('/')[1]}`,
  },
];

const VideoPlayer: FC<Props> = (props) => {
  const convertedUrl = useMemo(() => {
    const url = new URL(props.url);
    const applicableUrlConversion = urlConversions.find((urlConversion) =>
      urlConversion.hostnames.includes(url.hostname),
    );

    if (applicableUrlConversion === undefined) {
      return undefined;
    }
    return applicableUrlConversion?.convert(url);
  }, [props.url]);

  if (convertedUrl === undefined) {
    return (
      <div className={[styles.container, styles.invalidUrl].join(' ')}></div>
    );
  }

  return (
    <div className={styles.container} style={{ width: props.width }}>
      <iframe
        title="Video player"
        src={convertedUrl}
        allow="accelerometer; autoplay; encrypted-media; picture-in-picture"
        allowFullScreen
        className={styles.frame}
      />
    </div>
  );
};

export { VideoPlayer };
