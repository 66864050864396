import { IPublish } from '@hulanbv/toftennis';
import { IHttpOptions } from 'nest-utilities-client';
import { useState, useCallback, useEffect } from 'react';
import { publishService } from '../publish.service';

type UsePublishesHook = {
  publishes: IPublish[] | null;
  refresh: () => Promise<void>;
};

function usePublishes(
  options?: IHttpOptions,
  onCall?: boolean,
): UsePublishesHook {
  const [publishes, setpublishes] = useState<IPublish[] | null>(null);

  const fetchPublishes = useCallback(async () => {
    const { data } = await publishService.getAll(options);
    setpublishes(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- check for stringyfied changes
  }, [JSON.stringify(options)]);

  useEffect(() => {
    if (!onCall) {
      fetchPublishes();
    }
  }, [fetchPublishes, onCall]);

  return { publishes, refresh: fetchPublishes };
}

export { usePublishes };
