import { IPhase, PhaseColor, PhaseMastery } from '@hulanbv/toftennis';
import { HTMLAttributes } from 'react';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { UserPhaseListItemElement } from '../elements/user-phase-list-item-element/user-phase-list-item-element.component';

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;
  /**
   * The color of the phases.
   */
  color: PhaseColor;

  /**
   * The phases which need to be shown
   */
  phases: IPhase[];

  /**
   * Array of maximum acquired level with mastery at phaseIndex
   */
  achievedPhaseLevels: [number, PhaseMastery][];

  /**
   * Callback for whenever the level of a phase is changed.
   */
  onLevelChange: (
    newLevel: number,
    newMastery: PhaseMastery,
    phase: IPhase,
  ) => void | Promise<void>;
};

function UserPhasesListTemplate(props: Props): JSX.Element {
  return (
    <FlexElement gap={0} attributes={props.attributes}>
      {props.phases.map((phase) => (
        <UserPhaseListItemElement
          key={phase.phaseIndex}
          phase={phase}
          onLevelChange={(level, mastery) =>
            props.onLevelChange(level, mastery, phase)
          }
          level={props.achievedPhaseLevels?.[phase.phaseIndex]?.[0]}
          mastery={props.achievedPhaseLevels?.[phase.phaseIndex]?.[1]}
        />
      ))}
    </FlexElement>
  );
}

export { UserPhasesListTemplate };
