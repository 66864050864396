import { IClub } from '@hulanbv/toftennis';
import { IHttpOptions } from 'nest-utilities-client';
import { useCallback, useEffect, useState } from 'react';
import { clubService } from '../club.service';

type UseClubsHook = {
  clubs: IClub[] | null;
  refresh: () => Promise<void>;
};

function useClubs(options?: IHttpOptions): UseClubsHook {
  const [clubs, setClubs] = useState<IClub[] | null>(null);

  const fetchClubs = useCallback(async () => {
    const { data } = await clubService.getAll(options);
    setClubs(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- check for stringyfied changes
  }, [JSON.stringify(options)]);

  useEffect(() => {
    fetchClubs();
  }, [fetchClubs]);

  return { clubs, refresh: fetchClubs };
}

export { useClubs };
