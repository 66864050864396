import { IClub, IGroup, IUser, Sport } from '@hulanbv/toftennis';
import { FC } from 'react';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { sportLabels } from '../../domain/common/constants/sport-labels.constant';
import { sportIcons } from '../../domain/common/constants/sport-icons.constant';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { useSettingsContext } from '../../domain/settings/use-settings-context.hook';
import { useNotificationContext } from '../../domain/common/hooks/notification/use-notification-context.hook';
import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { ConfirmModalTemplate } from './confirm-modal-template.component';
import { groupService } from '../../domain/groups/group.service';
import { ModalElement } from '../elements/modal-element/modal-element.component';
import { GroupFormTemplate } from './group-form.template';

type Props = {
  onGroupCreated: (group: IGroup) => void;
  onGroupDeleted: (group: IGroup) => void;
  onGroupUpdated: (group: IGroup) => void;
  groups: IGroup[];
  clubs: IClub[];
  users: IUser[];
};

const GroupSettingsTemplate: FC<Props> = (props) => {
  const { activeSports, saveActiveSports } = useSettingsContext();
  const { openModal } = useModalContext();
  const { add } = useNotificationContext();
  const toggleActiveSport = (sport: Sport) => {
    const newActiveSports = activeSports.filter((active) => active !== sport);
    if (newActiveSports.length === activeSports.length) {
      newActiveSports.push(sport);
    }

    if (newActiveSports.length === 0) {
      add('Je moet minimaal 1 sport geselecteerd hebben.', 'error');
      return;
    }

    saveActiveSports(newActiveSports.concat().sort());
  };

  const showNewGroupModal = async () => {
    const data = await openModal<FormData | null>((resolve) => (
      <ModalElement>
        <FlexElement alignItems="stretch" gap={30}>
          <h2>Nieuwe groep</h2>
          <GroupFormTemplate
            users={props.users}
            clubs={props.clubs}
            onSubmit={resolve}
            onCancel={() => resolve(null)}
          />
        </FlexElement>
      </ModalElement>
    ));

    if (!data) {
      return;
    }

    const { data: group } = await groupService.post(data);
    props.onGroupCreated(group);
  };

  const showUpdateGroupModal = async (group: IGroup) => {
    if (props.clubs.length === 0) {
      return;
    }

    const data = await openModal<FormData | null>((resolve) => (
      <ModalElement>
        <FlexElement alignItems="stretch" gap={30}>
          <h2>Pas groep aan</h2>
          <GroupFormTemplate
            model={group}
            users={props.users}
            clubs={props.clubs}
            onSubmit={resolve}
            onCancel={() => resolve(null)}
          />
          <ButtonElement
            flavour="danger"
            size="small"
            attributes={{ onClick: () => deleteGroup(group) }}
          >
            Verwijder groep
          </ButtonElement>
        </FlexElement>
      </ModalElement>
    ));

    if (!data) {
      return;
    }

    const { data: updatedGroup } = await groupService.patch(data);
    props.onGroupUpdated(updatedGroup);
  };

  const deleteGroup = async (group: IGroup) => {
    const isConfirmed = await openModal<boolean>((resolve) => (
      <ConfirmModalTemplate
        content="Weet je zeker dat je deze groep wilt verwijderen?"
        resolve={resolve}
      />
    ));
    if (!isConfirmed) {
      return;
    }

    const { data: createdGroup } = await groupService.delete(group.id ?? '');
    props.onGroupDeleted(createdGroup);
  };

  return (
    <FlexElement
      attributes={{ style: { color: '#000', paddingTop: 15 } }}
      outerContentPadding
      gap={50}
    >
      <FlexElement>
        <h2>Mijn TOF programma's</h2>
        {Object.entries(sportLabels).map(([sportKey, label]) => {
          const sport = +sportKey as Sport;
          const isActive = activeSports.includes(sport);
          const Icon = sportIcons[+sport as Sport];
          return (
            <ButtonElement
              attributes={{
                style: isActive
                  ? undefined
                  : { color: 'var(--brand-text-secondary)' },
                onClick: () => toggleActiveSport(sport),
              }}
              key={sport}
              flavour={isActive ? 'ghost' : 'text'}
              size="small"
            >
              <FlexElement direction="row" gap={10}>
                <Icon height={'1.2em'} />
                {label}
              </FlexElement>
            </ButtonElement>
          );
        })}
      </FlexElement>

      <FlexElement>
        <h2>Mijn groepen</h2>
        {props.groups.length === 0 && <p>Je hebt nog geen groepen.</p>}
        {props.groups.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Naam</th>
                <th>Spelers</th>
              </tr>
            </thead>
            <tbody>
              {props.groups.map((group) => (
                <tr key={group.id} onClick={() => showUpdateGroupModal(group)}>
                  <td>{group.name}</td>
                  <td>{group.members?.length ?? 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {props.clubs.length > 0 && (
          <ButtonElement
            flavour="blue"
            size="small"
            attributes={{ onClick: showNewGroupModal }}
          >
            Nieuwe groep
          </ButtonElement>
        )}
      </FlexElement>
    </FlexElement>
  );
};

export { GroupSettingsTemplate };
