/**
 * Joins together a list of strings into a single string separated
 * by a space.
 * @param items
 * @returns
 */
const join = (...items: (string | false | null | undefined)[]): string =>
  items.filter(Boolean).join(' ');

export { join };
