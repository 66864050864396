import { IPublish } from '@hulanbv/toftennis';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<IPublish> {
  constructor() {
    super([process.env.REACT_APP_API_URL, 'publishes'].join('/'), httpService);
  }
}

export const publishService = new Service();
