import { createContext, ReactNode } from 'react';
import { useModal } from './use-modal.hook';

const ModalContext = createContext<ReturnType<typeof useModal>>({
  closeModal: () => {
    //
  },
  openModal: async (
    content: (resolve: (response: unknown) => void) => ReactNode,
  ) => null,
  modal: null,
});

export { ModalContext };
