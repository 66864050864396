import { PhaseColor, Sport } from '@hulanbv/toftennis';
import tennisBackgroundGreen from '../../../assets/images/swirl/tennis-green.png';
import tennisBackgroundOrange from '../../../assets/images/swirl/tennis-orange.png';
import tennisBackgroundRed from '../../../assets/images/swirl/tennis-red.png';
import paddelBackgroundGreen from '../../../assets/images/swirl/padel-green.png';
import paddelBackgroundOrange from '../../../assets/images/swirl/padel-orange.png';
import paddelBackgroundRed from '../../../assets/images/swirl/padel-red.png';

const swirlBackgrounds: Record<Sport, Record<PhaseColor, string>> = {
  [Sport.TENNIS]: {
    [PhaseColor.GREEN]: tennisBackgroundGreen,
    [PhaseColor.ORANGE]: tennisBackgroundOrange,
    [PhaseColor.RED]: tennisBackgroundRed,
  },
  [Sport.PADEL]: {
    [PhaseColor.GREEN]: paddelBackgroundGreen,
    [PhaseColor.ORANGE]: paddelBackgroundOrange,
    [PhaseColor.RED]: paddelBackgroundRed,
  },
  // [Sport.WHEELCHAIR_TENNIS]: {
  //   [PhaseColor.GREEN]: tennisBackgroundGreen,
  //   [PhaseColor.ORANGE]: tennisBackgroundOrange,
  //   [PhaseColor.RED]: tennisBackgroundRed,
  // },
};

export { swirlBackgrounds };
