import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuthContext } from '../../../domain/authentication/use-auth-context.hook';
import { IRoute } from '../../../domain/common/types/route.interface';

type Props = {
  routes: IRoute[];
};

function RouterElement(props: Props): JSX.Element {
  const { sessionToken } = useAuthContext();
  const [routes, setRoutes] = useState<IRoute[]>([]);

  // select only the routes to which the user has access
  useEffect(() => {
    const selectRoutes = async () => {
      const accessibleRoutes: IRoute[] = [];
      await Promise.all(
        props.routes.map(async (route) => {
          const isAccessible = await route.isAccessible?.();
          if (isAccessible !== false) {
            accessibleRoutes.push(route);
          }
        }),
      );

      setRoutes(accessibleRoutes);
    };

    selectRoutes();
  }, [props.routes, sessionToken]);

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path()}
          path={route.path()}
          element={<route.component />}
        />
      ))}
    </Routes>
  );
}

export { RouterElement };
