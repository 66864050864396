import { ICredentials, ISession } from '@hulanbv/toftennis';
import { CrudService, IHttpOptions, IResponse } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<ISession> {
  private sessionStorageKey = 'session';

  constructor() {
    super(
      [process.env.REACT_APP_API_URL, 'authentication'].join('/'),
      httpService,
    );
  }

  /**
   * Create an authenticated session
   * @param credentials FormData object with `username` and `password`
   */
  async login(
    credentials: ICredentials | FormData,
    options?: IHttpOptions,
  ): Promise<IResponse<ISession>> {
    const response = await this.http.post(
      this.controller,
      credentials,
      options,
    );

    this.storeSession(response.data);

    return response;
  }

  /**
   * Disable the current authorization token
   * @param options
   * @returns
   */
  async logout(options?: IHttpOptions): Promise<IResponse<void>> {
    const response = await this.http.post(
      [this.controller, 'logout'].join('/'),
      null,
      options,
    );
    this.storeSession(null);

    return response;
  }

  /**
   * Validate the currently used authorization token
   * @param options
   * @returns
   */
  async validate(): Promise<IResponse<ISession>> {
    const response = await this.http.post(
      [this.controller, 'validate'].join('/'),
      null,
      { populate: ['user'] },
    );
    this.storeSession(response.data);

    return response;
  }

  /**
   * Store the session token in the localstorage
   * @param session
   */
  storeSession(session: ISession | null) {
    if (session) {
      localStorage.setItem(this.sessionStorageKey, JSON.stringify(session));
    } else {
      localStorage.removeItem(this.sessionStorageKey);
    }
  }

  /**
   * Retrieve the session token from the local storage
   * @returns
   */
  getSession(): ISession | null {
    // attempt to parse the stored string to a JSON object
    try {
      return (
        JSON.parse(localStorage.getItem(this.sessionStorageKey) ?? '') || null
      );
    } catch {
      return null;
    }
  }
}

const authenticationService = new Service();

export { authenticationService };
