import { useEffect, useRef, useCallback } from 'react';

/**
 * @param callback The function to be called on each animation frame.
 */
function useAnimationFrame(callback: (deltaTime: number) => void): void {
  /**
   * Init ref with fake animation frame ID.
   */
  const animationFrameId = useRef(0);

  /**
   * The last time will be used to calculate the delta time.
   */
  const lastTime = useRef(0);

  const animate = useCallback(
    (time: number) => {
      // Calculate the delta time based on the previous time.
      const deltaTimeSeconds = (time - lastTime.current) / 1000;
      // Call the callback with the delta time.
      callback(deltaTimeSeconds);
      // Update the last to the current time in order to calculate the delta
      // time in the next frame.
      lastTime.current = time;
      // Update ref to new animation animation frame ID.
      animationFrameId.current = window.requestAnimationFrame(animate);
    },
    [callback],
  );

  useEffect(() => {
    // Update the ref to new animation frame ID.
    animationFrameId.current = window.requestAnimationFrame(animate);
    // kill animation cycle on component unmount
    return () => {
      // When the hook unmounts, cancel the animation frame.
      window.cancelAnimationFrame(animationFrameId.current);
    };
    // Start animation on first render.
  }, [animate]);
}

export { useAnimationFrame };
