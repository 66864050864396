import { IClub } from '@hulanbv/toftennis';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<IClub> {
  constructor() {
    super([process.env.REACT_APP_API_URL, 'clubs'].join('/'), httpService);
  }
}

const clubService = new Service();

export { clubService };
