import { IUserPhase, PhaseColor, Sport } from '@hulanbv/toftennis';
import { CrudService, IHttpOptions } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<IUserPhase> {
  constructor() {
    super(
      [process.env.REACT_APP_API_URL, 'user-phases'].join('/'),
      httpService,
    );
  }

  getBySportColor(sport: Sport, color: PhaseColor, options?: IHttpOptions) {
    return this.http.get<IUserPhase[]>(
      [this.controller, 'sport', sport, 'color', color].join('/'),
      options,
    );
  }
}

const userPhaseService = new Service();

export { userPhaseService };
