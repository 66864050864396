import { useNotificationContext } from '../../domain/common/hooks/notification/use-notification-context.hook';
import { NotificationCenterElement } from '../elements/notification-center-element/notification-center-element.component';

function ContextNotificationsTemplate(): JSX.Element {
  const { notifications } = useNotificationContext();

  return <NotificationCenterElement notifications={notifications} />;
}

export { ContextNotificationsTemplate };
