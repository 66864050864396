import { Sport } from '@hulanbv/toftennis';
import { ReactComponent as TennisIcon } from '../../../assets/graphics/toftennis-tennis.svg';
import { ReactComponent as PadelIcon } from '../../../assets/graphics/toftennis-padel.svg';

const sportIcons: Record<Sport, typeof TennisIcon> = {
  [Sport.TENNIS]: TennisIcon,
  [Sport.PADEL]: PadelIcon,
  // [Sport.WHEELCHAIR_TENNIS]: WheelchairTennisIcon,
};

export { sportIcons };
