import { useContext } from 'react';
import { SettingsContext } from './settings.context';
import { useSettings } from './use-settings.hook';

/**
 * Returns a context aware useSettings function
 * @returns
 */
function useSettingsContext(): ReturnType<typeof useSettings> {
  const context = useContext(SettingsContext);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
}

export { useSettingsContext };
