import { FC, useEffect, useMemo, useState } from 'react';
import { IUser, IUserScore } from '@hulanbv/toftennis';
import { Clique } from '../../domain/common/types/clique.type';
import { AsyncFlexElement } from '../elements/async-flex-element/async-flex-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ReactComponent as CrossIcon } from '../../assets/graphics/cross.svg';
import { userScoresService } from '../../domain/user-scores/user-scores.service';
import { TableRowElement } from '../elements/table-row-element/table-row.element';
import { ReactComponent as AllScoresIcon } from '../../assets/graphics/all-scores-filter.svg';
import { ReactComponent as HighestScoreIcon } from '../../assets/graphics/highest-score-filter.svg';
import { ReactComponent as AlphabeticalIcon } from '../../assets/graphics/alphabetical-filter.svg';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { ConfirmModalTemplate } from './confirm-modal-template.component';
import { useNotificationContext } from '../../domain/common/hooks/notification/use-notification-context.hook';
import { AddEventScoreModal } from './add-event-score-modal.template';
import { formatDate } from '../../domain/common/utilities/format-date.utility';

type Props = {
  selectedClique: Clique;
  workingUserIds: string[];
};

type Filters = 'all' | 'highest' | 'alphabetical';
const filters: Filters[] = ['highest', 'alphabetical', 'all'];
const filterIcons: Record<Filters, JSX.Element> = {
  all: (
    <>
      Alle scores <AllScoresIcon height={'1.2em'} />
    </>
  ),
  highest: (
    <>
      Hoogste totaal
      <HighestScoreIcon height={'1.2em'} />
    </>
  ),
  alphabetical: (
    <>
      Alfabetisch <AlphabeticalIcon height={'1.2em'} />
    </>
  ),
};

const UserScoresTemplate: FC<Props> = ({ selectedClique, workingUserIds }) => {
  const { openModal } = useModalContext();
  const { add: addNotification } = useNotificationContext();
  const [selectedFilter, setSelectedFilter] = useState<Filters>('highest');
  const [userScores, setUserScores] = useState<IUserScore[] | null>(null);
  const [userRecords, setUserRecords] = useState<
    | {
        user?: IUser;
        scores: IUserScore[];
        totalScore: number;
      }[]
    | null
  >(null);

  const gatherUserRecords = async () => {
    if (!selectedClique.members) {
      return;
    }
    const { data: userScores } = await userScoresService.getAll({
      match: {
        userId: { $in: workingUserIds },
      },
      useRequestHeaders: true,
      sort: ['-createdAt'],
    });

    const userScoresMap = userScores.reduce<Record<string, IUserScore[]>>(
      (acc, score) => {
        acc[score.userId] = (acc[score.userId] ?? []).concat(score);
        return acc;
      },
      {},
    );
    const membersMap = selectedClique.members.reduce<Record<string, IUser>>(
      (acc, member) => {
        acc[member.id ?? ''] = member;
        return acc;
      },
      {},
    );

    setUserScores(userScores);
    setUserRecords(
      workingUserIds.map((userId) => {
        const user = membersMap[userId];
        const scores = userScoresMap[userId] ?? [];
        return {
          user,
          scores,
          totalScore: scores.reduce((acc, score) => acc + score.score, 0),
        };
      }),
    );
  };

  useEffect(() => {
    setUserRecords(null);
    setUserScores(null);
    gatherUserRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- gatherUserRecords is a dependency
  }, [selectedClique.members, workingUserIds]);

  const sortedUserRecords = useMemo(
    () =>
      [...(userRecords ?? [])].sort((a, b) => {
        if (selectedFilter === 'alphabetical') {
          return a.user?.firstName?.localeCompare(b.user?.firstName ?? '') ?? 0;
        }
        return b.totalScore - a.totalScore;
      }),
    [selectedFilter, userRecords],
  );

  const handleDeleteClick = async (userScoreId: string) => {
    try {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModalTemplate
          resolve={resolve}
          cancelButtonText="Annuleren"
          confirmButtonText="Verwijderen"
          content={<p>Weet je zeker dat je deze score wilt verwijderen?</p>}
        />
      ));
      if (!isConfirmed) {
        return;
      }

      // publish the data
      await userScoresService.delete(userScoreId);

      addNotification('De score is succesvol verwijderd', 'success');
      gatherUserRecords();
    } catch {
      addNotification(
        'Er is iets misgegaan bij het verwijderen van de score',
        'error',
      );
    }
  };

  const handleAddClick = async (userId: string) => {
    try {
      const addedScore = await openModal<number>((resolve) => (
        <AddEventScoreModal resolve={resolve} />
      ));
      if (addedScore === null) {
        return;
      }

      // publish the data
      await userScoresService.post({
        userId,
        score: addedScore,
      });

      addNotification('De score is toegevoegd aan de gebruiker', 'success');
      gatherUserRecords();
    } catch {
      addNotification(
        'Er is iets misgegaan bij het toevoegen van de score',
        'error',
      );
    }
  };

  return (
    <AsyncFlexElement
      flexProps={{
        flex: 1,
        justifyContent: 'flex-start',
      }}
    >
      <FlexElement
        outerContentPadding
        direction="row"
        justifyContent="flex-end"
      >
        <ButtonElement
          attributes={{
            style: { flex: 0 },
            onClick: () => {
              const index = filters.indexOf(selectedFilter);
              setSelectedFilter(
                filters[(index + 1) % filters.length] ?? 'highest',
              );
            },
          }}
          flavour="blue"
          children={
            <FlexElement
              direction="row"
              attributes={{ style: { whiteSpace: 'nowrap' } }}
            >
              {filterIcons[selectedFilter]}
            </FlexElement>
          }
        />
      </FlexElement>

      {userRecords !== null && (
        <FlexElement gap={0}>
          {selectedFilter !== 'all' &&
            sortedUserRecords.map(({ user, scores, totalScore }) => (
              <TableRowElement
                onAddClick={() => handleAddClick(user?.id ?? '')}
                key={user?.id}
                title={`${user?.firstName} ${user?.lastName}`}
                subContent={
                  <FlexElement
                    direction="row"
                    attributes={{
                      style: { color: 'var(--brand-text-secondary)' },
                    }}
                  >
                    <p style={{ flex: 1 }}>
                      Totaal score <strong>{totalScore}</strong>
                    </p>
                    <p>
                      {scores.length} update{scores.length !== 1 && 's'}
                    </p>
                  </FlexElement>
                }
              />
            ))}

          {selectedFilter === 'all' &&
            userScores?.map(({ id, userId, score, createdAt }) => {
              const user = selectedClique.members?.find(
                (member) => member.id === userId,
              );
              return (
                <TableRowElement
                  onDeleteClick={() => handleDeleteClick(id ?? '')}
                  key={id ?? ''}
                  title={`${user?.firstName} ${user?.lastName}`}
                  subContent={
                    <FlexElement
                      direction="row"
                      attributes={{
                        style: { color: 'var(--brand-text-secondary)' },
                      }}
                    >
                      <p style={{ flex: 1 }}>
                        Score <strong>{score}</strong>
                      </p>
                      <p>{formatDate(createdAt)}</p>
                    </FlexElement>
                  }
                />
              );
            })}
        </FlexElement>
      )}

      {selectedFilter === 'all' && !userScores?.length && (
        <FlexElement flex={1}>
          <FlexElement
            direction="row"
            attributes={{
              style: {
                color: 'var(--brand-text-secondary)',
                fontSize: '1.2rem',
              },
            }}
          >
            <CrossIcon height={'1em'} fill="var(--brand-text-secondary)" />
            Geen scores beschikbaar
          </FlexElement>
        </FlexElement>
      )}
    </AsyncFlexElement>
  );
};

export { UserScoresTemplate };
