import { RoutableComponent } from '../types/routable-component.type';
import { IRoute } from '../types/route.interface';

/**
 * Get a concatenated array of all route variations in the provided screens.
 * @param routables
 * @returns
 */
const getRoutesFromRoutables = (routables: RoutableComponent[]): IRoute[] =>
  routables.reduce<IRoute[]>(
    (prev, curr) => prev.concat(Object.values(curr.routes)),
    [],
  );

export { getRoutesFromRoutables };
