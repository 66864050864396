import { ReactNode, useCallback, useState } from 'react';

type Notification = {
  content: ReactNode;
  type: 'success' | 'error' | 'warning';
  durationMs: number;
};

type UseNotificationResponse = {
  add: (
    content: Notification['content'],
    type?: Notification['type'],
    durationMs?: Notification['durationMs'],
  ) => void;
  notifications: Notification[];
};

function useNotification(): UseNotificationResponse {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const add = useCallback(
    (
      content: Notification['content'],
      type?: Notification['type'],
      durationMs?: Notification['durationMs'],
    ) => {
      // create and add the notification
      const notification = {
        content,
        type: type ?? 'success',
        durationMs: durationMs ?? 3000,
      };
      setNotifications((state) => state.concat(notification));

      // close the notification after the duration has passed
      window.setTimeout(
        () =>
          setNotifications((state) =>
            state.filter((present) => present !== notification),
          ),
        notification.durationMs,
      );
    },
    [],
  );

  return { add, notifications };
}

export { useNotification };
export type { UseNotificationResponse, Notification };
