import {
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './select-element.module.css';
import { ReactComponent as FilledArrowDownSvg } from '../../../assets/graphics/filled-arrow-down-icon.svg';
import { EllipsisElement } from '../ellipsis-element/ellipsis-element.component';

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;
  options: [value: string, label: ReactNode][];
  defaultValue?: string[];
  onChange?: (value: string[]) => void;
  multiselect?: boolean;
  required?: boolean;
  name?: string;
  placeholder?: ReactNode;
  hasDarkText?: boolean;
  isDisabled?: boolean;
};

// eslint-disable-next-line complexity -- we need to handle a lot of states
function SelectElement(props: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [selection, setSelection] = useState<string[]>(
    props.defaultValue ?? [],
  );
  const selectedOption = useMemo(() => {
    if (selection.length === 0) {
      return null;
    }
    return props.options.find((option) => selection.includes(option[0]));
  }, [props.options, selection]);

  const blurSelectBox = useCallback(() => {
    (document.activeElement as HTMLElement)?.blur();
  }, []);

  const handleOptionClick = (value: string) => {
    let newSelection: string[] = [];
    if (selection.includes(value) && props.multiselect) {
      newSelection = selection.filter((item) => item !== value);
    } else if (!selection.includes(value) && props.multiselect) {
      newSelection = [...selection, value];
    } else {
      newSelection = [value];
    }

    // always blur after a click when multiselect is disabled
    if (!props.multiselect) {
      blurSelectBox();
    }
    setSelection(newSelection);
    props.onChange?.(newSelection);
  };

  return (
    <div
      {...{
        ...props.attributes,
        className: join(
          styles.select,
          isOpen && styles.opened,
          selection.length > 1 && styles.multipleSelected,
          props.hasDarkText && styles.darkText,
          props.attributes?.className,
        ),
        onFocus: () => !props.isDisabled && setIsOpen(true),
        onBlur: () => setIsOpen(false),
        tabIndex: -1,
      }}
    >
      {props.name &&
        props.multiselect &&
        selection.map((value) => (
          <input key={value} type="hidden" name={props.name} value={value} />
        ))}
      {props.name && !props.multiselect && selection.length > 0 && (
        <input type="hidden" name={props.name} value={selection[0]} />
      )}

      <div className={styles.valueContainer}>
        <FlexElement minHeight={25} direction="row" justifyContent="flex-start">
          <EllipsisElement>
            {selectedOption?.[1] ?? props.placeholder ?? '-'}
          </EllipsisElement>
        </FlexElement>
        {props.required && selection.length === 0 && (
          <input
            className={styles.requiredInput}
            type="text"
            onFocus={(event) => event.target.blur()}
            required
          />
        )}

        <FlexElement
          attributes={{ className: styles.metadataContainer }}
          direction="row"
          justifyContent="flex-end"
          minWidth="fit-content"
          gap={5}
        >
          {selection.length > 1 && (
            <div className={styles.selectionCounter}>
              +{selection.length - 1}
            </div>
          )}
          <FilledArrowDownSvg
            width={25}
            height={25}
            className={styles.dropdownArrow}
          />
        </FlexElement>
        {isOpen && (
          <div onClick={blurSelectBox} className={styles.deselector} />
        )}
      </div>

      {isOpen && (
        <div className={styles.dropdown}>
          {props.options.map(([value, label]) => (
            <FlexElement
              direction="row"
              justifyContent="flex-start"
              attributes={{
                className: join(
                  styles.option,
                  selection.includes(value) && styles.selectedOption,
                ),
                onClick: () => handleOptionClick(value),
              }}
              key={value}
            >
              <EllipsisElement>{label}</EllipsisElement>
            </FlexElement>
          ))}

          {props.options.length === 0 && (
            <FlexElement
              attributes={{
                className: join(styles.option),
                style: { textAlign: 'center' },
              }}
            >
              Geen opties beschikbaar
            </FlexElement>
          )}
        </div>
      )}
    </div>
  );
}

export { SelectElement };
