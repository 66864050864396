import { FunctionComponent, ReactNode, SVGProps } from 'react';
import {
  ButtonElement,
  ButtonProps,
} from '../button-element/button-element.component';
import styles from './button-icon-element.module.css';
import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';

type Props = ButtonProps & {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  children?: ReactNode;
  isDisabled?: boolean;
};

function ButtonIconElement(props: Props): JSX.Element {
  return (
    <ButtonElement
      {...props}
      attributes={{
        ...props.attributes,
        className: join(
          styles.button,
          props.isDisabled && styles.disabled,
          props.attributes?.className,
        ),
      }}
    >
      <FlexElement direction="row">
        {props.children}
        <props.icon width={25} height={25} />
      </FlexElement>
    </ButtonElement>
  );
}

export { ButtonIconElement };
