/* eslint-disable max-lines -- complex method */
import { useMemo, ReactNode, useState, useCallback, useEffect } from 'react';
import { IUser, Role } from '@hulanbv/toftennis';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { routable } from '../../domain/common/utilities/routable.utility';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { SelectElement } from '../elements/select-element/select-element.component';
import { useGroups } from '../../domain/groups/hooks/use-groups.hook';
import { useAuthContext } from '../../domain/authentication/use-auth-context.hook';
import { useClubs } from '../../domain/clubs/hooks/use-clubs.hook';
import { Clique } from '../../domain/common/types/clique.type';
import { PhaseLevelsTemplate } from '../templates/phase-levels-template';
import { GroupSettingsTemplate } from '../templates/group-settings.template';
import { useSettingsContext } from '../../domain/settings/use-settings-context.hook';
import { UserScoresTemplate } from '../templates/user-scores';

type Views = 'swirl' | 'list' | 'scores' | 'groups';

const GroupScreen = routable(
  {
    root: {
      name: () => 'Selecteer groep',
      path: () => '/group',
      isAccessible: () => hasRole(),
    },
  },
  // eslint-disable-next-line complexity -- we need to handle a lot of states
  () => {
    const { activeSports } = useSettingsContext();
    const { sessionToken } = useAuthContext();
    const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
    const [selectedClique, setSelectedClique] = useState<Clique | null>(null);
    const [activeView, setActiveView] = useState<Views>('swirl');

    const views: [Views, string][] = useMemo(() => {
      const views: [Views, string][] = [
        ['swirl', 'Swirl'],
        ['list', "Info/video's"],
      ];
      if (selectedClique?.club?.hasEventsModule) {
        views.push(['scores', 'TOF score']);
      }

      views.push(['groups', 'Mijn TOF']);

      return views;
    }, [selectedClique?.club?.hasEventsModule]);

    const { clubs: unsortedClubs } = useClubs({
      match: { _id: { $in: sessionToken?.user?.clubIds ?? [] } },
      populate: [{ path: 'members' }],
    });
    const clubs = useMemo(
      () =>
        unsortedClubs?.map((club) => {
          club.members?.sort((a, b) => {
            const aFullName = [a.firstName, a.preposition, a.lastName]
              .filter(Boolean)
              .join(' ')
              .toLowerCase();
            const bFullName = [b.firstName, b.preposition, b.lastName]
              .filter(Boolean)
              .join(' ')
              .toLowerCase();

            if (aFullName < bFullName) {
              return -1;
            }
            if (aFullName > bFullName) {
              return 1;
            }
            return 0;
          });

          return club;
        }),
      [unsortedClubs],
    );

    const allMembers = useMemo(
      () =>
        clubs?.reduce<IUser[]>(
          (prev, curr) => prev.concat(curr.members ?? []),
          [],
        ) ?? [],
      [clubs],
    );

    const { groups: unsortedGroups, refresh: refreshGroups } = useGroups({
      match: {
        'memberIds.0': { $exists: true },
        sport: { $in: activeSports },
        $or: [{ instructorIds: sessionToken?.userId }],
      },
      populate: ['members', 'club'],
    });

    const groups = useMemo(
      () =>
        unsortedGroups?.map((group) => {
          group.members?.sort((a, b) => {
            const aFullName = [a.firstName, a.preposition, a.lastName]
              .filter(Boolean)
              .join(' ')
              .toLowerCase();
            const bFullName = [b.firstName, b.preposition, b.lastName]
              .filter(Boolean)
              .join(' ')
              .toLowerCase();

            if (aFullName < bFullName) {
              return -1;
            }
            if (aFullName > bFullName) {
              return 1;
            }
            return 0;
          });

          return group;
        }),
      [unsortedGroups],
    );

    const cliqueOptions = useMemo(() => {
      const options: [string, ReactNode][] = [];
      options.push(
        ...(clubs?.map<[string, string]>(({ id, name }) => [id ?? '', name]) ??
          []),
      );
      options.push(
        ...(groups?.reduce<[string, ReactNode][]>(
          (prev, curr) => prev.concat([[curr.id ?? '', curr.name]]),
          [],
        ) ?? []),
      );

      return options;
    }, [clubs, groups]);

    const workingUserIds = useMemo(() => {
      if (selectedMemberIds.length) {
        return selectedMemberIds;
      }
      return selectedClique?.members?.map(({ id }) => id ?? '') ?? [];
    }, [selectedClique?.members, selectedMemberIds]);

    const memberOptions = useMemo(() => {
      const options: [string, ReactNode][] = [];
      selectedClique?.members
        ?.filter(({ role }) => role === Role.PLAYER)
        .forEach((member) =>
          options.push([
            member.id ?? '',
            [member.firstName, member.preposition, member.lastName]
              .filter(Boolean)
              .join(' '),
          ]),
        );
      return options;
    }, [selectedClique?.members]);

    /**
     * Select the first group by default when the groups are fetched
     */
    useEffect(() => {
      const group = groups?.[0];
      if (!Array.isArray(groups) || !Array.isArray(clubs)) {
        return;
      }

      const club = clubs?.find(({ id }) => group?.clubId === id) ?? clubs?.[0];
      setSelectedClique({
        id: group?.id ?? club?.id,
        group,
        club,
        members: (group?.members ?? club?.members)?.filter(
          ({ role }) => role === Role.PLAYER,
        ),
        type: group ? 'group' : 'club',
      });
    }, [groups, clubs]);

    useEffect(() => {
      setSelectedMemberIds([]);
    }, [groups, selectedClique]);

    const handleCliqueSelection = useCallback(
      (values: string[]) => {
        const group = groups?.find(({ id }) => id === values[0]) ?? null;
        const isGroupSelected = group !== null;
        const club =
          clubs?.find(({ id }) => id === values[0] || group?.clubId === id) ??
          null;

        setSelectedClique({
          id: isGroupSelected ? group?.id : club?.id,
          club: club ?? undefined,
          group: group ?? undefined,
          members: (group?.members ?? club?.members ?? []).filter(
            ({ role }) => role === Role.PLAYER,
          ),
          type: isGroupSelected ? 'group' : 'club',
        });
      },
      [clubs, groups],
    );

    return (
      <FlexElement justifyContent="flex-start" minHeight="100vh">
        {selectedClique !== null && (
          <>
            <FlexElement
              key={selectedClique?.id}
              justifyContent="flex-start"
              direction="row"
              outerContentPadding
              flex={0}
              attributes={{
                style: { paddingTop: '15px' },
              }}
            >
              <SelectElement
                onChange={handleCliqueSelection}
                options={cliqueOptions}
                placeholder="Clubs/groepen"
                defaultValue={[selectedClique?.id ?? '']}
                key={selectedClique?.id}
              />
              <SelectElement
                onChange={setSelectedMemberIds}
                options={memberOptions}
                placeholder="Alle spelers"
                multiselect
              />
            </FlexElement>
            <FlexElement
              flex={1}
              justifyContent="flex-start"
              attributes={{
                style: {
                  background: 'white',
                  paddingTop: '15px',
                },
              }}
            >
              <FlexElement
                justifyContent="flex-start"
                direction="row"
                flex={0}
                outerContentPadding
              >
                {views.map(([view, label]) => (
                  <p
                    key={view}
                    onClick={() => setActiveView(view)}
                    style={{
                      opacity: activeView === view ? 1 : 0.5,
                      color: 'var(--brand-text-dark)',
                    }}
                  >
                    {label}
                  </p>
                ))}
              </FlexElement>
              <FlexElement flex={1} justifyContent="start">
                {['list', 'swirl'].includes(activeView) && (
                  <PhaseLevelsTemplate
                    selectedClique={selectedClique}
                    workingUserIds={workingUserIds}
                    view={activeView === 'list' ? 'list' : 'swirl'}
                  />
                )}

                {activeView === 'scores' && (
                  <UserScoresTemplate
                    selectedClique={selectedClique}
                    workingUserIds={workingUserIds}
                  />
                )}

                {activeView === 'groups' && (
                  <GroupSettingsTemplate
                    groups={groups ?? []}
                    clubs={clubs ?? []}
                    users={allMembers}
                    onGroupCreated={refreshGroups}
                    onGroupDeleted={refreshGroups}
                    onGroupUpdated={refreshGroups}
                  />
                )}
              </FlexElement>
            </FlexElement>
          </>
        )}
      </FlexElement>
    );
  },
);

export { GroupScreen };

/* eslint-enable max-lines -- complex method */
