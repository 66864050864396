import { useContext } from 'react';
import { NotificationContext } from './notification.context';
import { useNotification } from './use-notification.hook';

/**
 * Returns a context aware useNotification function
 * @returns
 */
function useNotificationContext(): ReturnType<typeof useNotification> {
  const context = useContext(NotificationContext);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
}

export { useNotificationContext };
