import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';

type Props = {
  title: string;
  description: string;
  confirmButtonText?: string;
};

function InfoModalTemplate(props: Props): JSX.Element {
  const { closeModal } = useModalContext();

  return (
    <FlexElement alignItems="flex-start" gap={30}>
      <FlexElement alignItems="flex-start" gap={10}>
        <h2>{props.title}</h2>
      </FlexElement>
      <FlexElement direction="row">
        <p>{props.description}</p>
      </FlexElement>
      <ButtonElement flavour="blue" attributes={{ onClick: closeModal }}>
        {props.confirmButtonText ?? 'Sluiten'}
      </ButtonElement>
    </FlexElement>
  );
}

export { InfoModalTemplate };
