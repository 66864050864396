import { useContext } from 'react';
import { ModalContext } from './modal.context';
import { useModal } from './use-modal.hook';

/**
 * Returns a context aware useModal function
 * @returns
 */
function useModalContext(): ReturnType<typeof useModal> {
  const context = useContext(ModalContext);
  if (context === null) {
    throw new Error('Context Provider not initialized!');
  }
  return context;
}

export { useModalContext };
