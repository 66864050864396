import { useCallback } from 'react';
import { routable } from '../../domain/common/utilities/routable.utility';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ReactComponent as LogoSvg } from '../../assets/graphics/logo.svg';
import { LoginFormTemplate } from '../templates/login-form-template.component';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { useAuthContext } from '../../domain/authentication/use-auth-context.hook';
import { useNotificationContext } from '../../domain/common/hooks/notification/use-notification-context.hook';

const LoginScreen = routable(
  {
    root: {
      name: () => 'Inloggen',
      path: () => '/login',
      isAccessible: () => !hasRole(),
    },
  },
  () => {
    const { login } = useAuthContext();
    const { add } = useNotificationContext();
    const handleLogin = useCallback(
      async (formData: FormData) => {
        try {
          await login(formData);
        } catch {
          add('Onjuiste inloggegevens');
        }
      },
      [add, login],
    );

    return (
      <FlexElement minHeight="100vh" outerContentPadding>
        <FlexElement flex={1} gap={30}>
          <LogoSvg width={200} height={89} />
          <h1>Login</h1>
        </FlexElement>

        <FlexElement justifyContent="flex-start" flex={2}>
          <LoginFormTemplate onSubmit={handleLogin} />
        </FlexElement>
      </FlexElement>
    );
  },
);

export { LoginScreen };
