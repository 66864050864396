import { IUserScore } from '@hulanbv/toftennis';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<IUserScore> {
  constructor() {
    super(
      [process.env.REACT_APP_API_URL, 'user-scores'].join('/'),
      httpService,
    );
  }
}

const userScoresService = new Service();

export { userScoresService };
