import { InputHTMLAttributes } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './input-element.module.css';

type Props = {
  attributes?: InputHTMLAttributes<HTMLInputElement>;
  hasDarkText?: boolean;
};

function InputElement(props: Props): JSX.Element {
  return (
    <input
      {...{
        ...props.attributes,
        className: join(
          styles.input,
          props.hasDarkText && styles.darkText,
          props.attributes?.className,
        ),
      }}
    />
  );
}

export { InputElement };
