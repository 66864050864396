import { IUserPhase, PhaseColor, Sport } from '@hulanbv/toftennis';
import { IHttpOptions } from 'nest-utilities-client';
import { useCallback, useEffect, useState } from 'react';
import { userPhaseService } from '../user-phase.service';

type UseUserPhasesHook = {
  userPhases?: IUserPhase[];
  refresh: () => Promise<void>;
};

function useUserPhasesByColor(
  sport: Sport,
  color: PhaseColor,
  options?: IHttpOptions<IUserPhase>,
  onCall?: boolean,
): UseUserPhasesHook {
  const [userPhases, setUserPhases] = useState<IUserPhase[]>([]);

  const fetchUserPhases = useCallback(async () => {
    const { data } = await userPhaseService.getBySportColor(
      sport,
      color,
      options,
    );
    setUserPhases(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- check for stringified changes
  }, [JSON.stringify(options)]);

  useEffect(() => {
    if (!onCall) {
      fetchUserPhases();
    }
  }, [fetchUserPhases, onCall]);

  return { userPhases, refresh: fetchUserPhases };
}

export { useUserPhasesByColor };
