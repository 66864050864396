import { ReactNode, useCallback, useRef, useState } from 'react';
import { IModal } from '../../types/modal.interface';

type ModalHook = {
  modal: IModal | null;
  openModal: <ResolveType = null>(
    content: (resolve: (response: ResolveType) => void) => ReactNode,
  ) => Promise<ResolveType | null>;
  closeModal: () => void;
};

/**
 * A hook to manage the modal component
 * @returns
 */
function useModal(): ModalHook {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- the type is defined by the generic of the openModal function
  const resolveRef = useRef<((value: any | null) => void) | null>(null);
  const [modal, setModal] = useState<IModal | null>(null);

  const openModal = useCallback(
    async <ResolveType = null>(
      content: (resolve: (response: ResolveType | null) => void) => ReactNode,
      width?: number,
    ) => {
      // resolve already opened modals
      resolveRef.current?.(null);

      // wait for the modal to be resolved
      return new Promise<ResolveType | null>((resolve) => {
        resolveRef.current = (response: ResolveType | null) => {
          // clean up the resolved modal
          resolveRef.current = null;
          setModal(null);
          document.body.style.overflow = '';

          // send the response
          resolve(response);
        };

        // set the new modal and move the resolve method to the state
        setModal({ content: content(resolveRef.current), width: width ?? 600 });
        document.body.style.overflow = 'hidden';
      });
    },
    [],
  );

  /**
   * Closes any opened modals and resolves them with null
   */
  const closeModal = useCallback(() => {
    resolveRef.current?.(null);
  }, []);

  return {
    modal,
    openModal,
    closeModal,
  };
}

export { useModal };
