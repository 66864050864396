import { GroupScreen } from '../../../components/screens/group-screen.component';
import { LoginScreen } from '../../../components/screens/login-screen.component';
import { NotFoundScreen } from '../../../components/screens/not-found-screen.component';
import { getRoutesFromRoutables } from '../utilities/get-routes-from-routables.utility';

export const routes = getRoutesFromRoutables([
  LoginScreen,
  GroupScreen,
  NotFoundScreen,
]);
