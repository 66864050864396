import { FC, ReactNode } from 'react';
import { FlexElement } from '../flex-element/flex-element.component';
import { ReactComponent as PlusIcon } from '../../../assets/graphics/toftennis-plus.svg';
import { ReactComponent as TrashIcon } from '../../../assets/graphics/toftennis-trash.svg';
import styles from './table-row.module.css';
import { ButtonElement } from '../button-element/button-element.component';

type Props = {
  onDeleteClick?: () => void | Promise<void>;
  onAddClick?: () => void | Promise<void>;

  title: ReactNode;
  subContent?: ReactNode;
};

const TableRowElement: FC<Props> = (props) => (
  <FlexElement direction="row" attributes={{ className: styles.tableRow }}>
    <FlexElement direction="row" flex={1} outerContentPadding>
      <FlexElement direction="column" flex={1} alignItems="start">
        <h2>{props.title}</h2>
        {props.subContent}
      </FlexElement>
      <FlexElement direction="row" flex={0}>
        {props.onDeleteClick && (
          <ButtonElement
            attributes={{ onClick: props.onDeleteClick }}
            flavour="danger"
            children={<TrashIcon height={'1.2em'} />}
            size="small"
          />
        )}
        {props.onAddClick && (
          <ButtonElement
            attributes={{ onClick: props.onAddClick }}
            flavour="orange"
            children={<PlusIcon height={'1.2em'} />}
            size="small"
          />
        )}
      </FlexElement>
    </FlexElement>
  </FlexElement>
);

export { TableRowElement };
