import { Navigate } from 'react-router-dom';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { routable } from '../../domain/common/utilities/routable.utility';
import { GroupScreen } from './group-screen.component';
import { LoginScreen } from './login-screen.component';

const NotFoundScreen = routable(
  {
    root: {
      name: () => 'Pagina niet gevonden',
      path: () => '*',
    },
  },
  () => {
    // if the user is logged in, redirect to the home screen
    if (hasRole()) {
      return <Navigate to={GroupScreen.paths.root()} replace />;
    }

    // if the user is not logged in, redirect to the login screen
    return <Navigate to={LoginScreen.paths.root()} replace />;
  },
);

export { NotFoundScreen };
