import { BrowserRouter } from 'react-router-dom';
import { AuthContext } from '../domain/authentication/auth.context';
import { useAuth } from '../domain/authentication/use-auth.hook';
import { ModalContext } from '../domain/common/hooks/modal/modal.context';
import { useModal } from '../domain/common/hooks/modal/use-modal.hook';
import { NotificationContext } from '../domain/common/hooks/notification/notification.context';
import { useNotification } from '../domain/common/hooks/notification/use-notification.hook';
import { httpService } from '../domain/common/http-service';
import { Application } from './application.component';
import { ContextModalTemplate } from './templates/context-modal-template.component';
import { ContextNotificationsTemplate } from './templates/context-notifications-template.component';
import { SettingsContext } from '../domain/settings/settings.context';
import { useSettings } from '../domain/settings/use-settings.hook';

// First entry point where the application is bootstrapped.
function Bootstrap(): JSX.Element {
  const authHook = useAuth();
  const settingsHook = useSettings();
  const modalHook = useModal();
  const notificationHook = useNotification();
  httpService.authContext = authHook;

  return (
    <AuthContext.Provider value={authHook}>
      <SettingsContext.Provider value={settingsHook}>
        <ModalContext.Provider value={modalHook}>
          <NotificationContext.Provider value={notificationHook}>
            <BrowserRouter>
              <Application />
            </BrowserRouter>
            <ContextModalTemplate />
            <ContextNotificationsTemplate />
          </NotificationContext.Provider>
        </ModalContext.Provider>
      </SettingsContext.Provider>
    </AuthContext.Provider>
  );
}

export { Bootstrap };
