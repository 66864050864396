import { Role } from '@hulanbv/toftennis';
import { authenticationService } from '../authentication.service';

/**
 * Verify if the user's role is equal to one of the role parameters.
 * No provided roles will verify if the user has a session.
 */
const hasRole = (...roles: Role[]): boolean => {
  const session = authenticationService.getSession();
  if (!roles.length) {
    return !!session;
  }
  return roles.includes(session?.user?.role as Role);
};

export { hasRole };
