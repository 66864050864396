import { IGroup } from '@hulanbv/toftennis';
import { CrudService } from 'nest-utilities-client';
import { httpService } from '../common/http-service';

class Service extends CrudService<IGroup> {
  constructor() {
    super([process.env.REACT_APP_API_URL, 'groups'].join('/'), httpService);
  }
}

const groupService = new Service();

export { groupService };
