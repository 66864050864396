import { RoutableComponent } from '../types/routable-component.type';
import { IRoute } from '../types/route.interface';

/**
 * Makes a component approachable on the given route variations.
 * @param routeVariations The route variations on which the component is reachable.
 * The key will describe what variation the route is for ("add", "delete", "details", etc.)
 * and the value contains the route itself.
 * @param component The Functional Component to be made approachable.
 */
function routable<
  RouteVariations extends Record<string, Omit<IRoute, 'component'>>,
>(
  routeVariations: RouteVariations,
  component: () => JSX.Element,
): RoutableComponent<RouteVariations & Record<string, IRoute>> {
  const routes: Record<string, IRoute> = {};
  const paths: Record<string, (...args: (string | number)[]) => string> = {};
  for (const [key, variation] of Object.entries(routeVariations)) {
    paths[key] = variation.path;
    routes[key] = {
      ...variation,
      component,
    };
  }

  const routableComponent: {
    (): JSX.Element;
    paths?: typeof paths;
    routes?: typeof routes;
  } = component;

  // add the paths and route as prototypes to the screens
  routableComponent.paths = paths;
  routableComponent.routes = routes;

  return routableComponent as unknown as RoutableComponent<
    RouteVariations & Record<string, IRoute>
  >;
}

export { routable };
