import { Sport } from '@hulanbv/toftennis';
import { sportLabels } from '../common/constants/sport-labels.constant';

type Settings = {
  activeSports: Sport[];
};

class Service {
  private settingsStoreKey = 'settings';

  private settings: Settings = this.decodeSettings();

  public get activeSports(): Sport[] {
    return this.settings.activeSports;
  }

  public set activeSports(activeSports: Sport[]) {
    this.settings.activeSports = activeSports;
    this.encodeSettings();
  }

  private decodeSettings(): Settings {
    const settings = localStorage.getItem(this.settingsStoreKey);
    if (settings) {
      const decodedSettings = JSON.parse(settings);
      decodedSettings.activeSports = decodedSettings.activeSports.filter(
        (sport: number) => sport in sportLabels,
      );
      return decodedSettings;
    }
    return {
      activeSports: Object.keys(sportLabels).map((key) => +key as Sport),
    };
  }

  private encodeSettings(): void {
    localStorage.setItem(this.settingsStoreKey, JSON.stringify(this.settings));
  }
}

const settingsService = new Service();

export { settingsService };
