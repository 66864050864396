import {
  CSSProperties,
  HTMLAttributes,
  PropsWithChildren,
  RefObject,
  useMemo,
} from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './flex-element.module.css';

type Props = PropsWithChildren<{
  attributes?: HTMLAttributes<HTMLDivElement>;
  flex?: CSSProperties['flex'];
  direction?: CSSProperties['flexDirection'];
  gap?: number;
  wrap?: CSSProperties['flexWrap'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  minHeight?: CSSProperties['minHeight'];
  minWidth?: CSSProperties['minWidth'];

  outerContentPadding?: boolean;

  elementRef?: RefObject<HTMLDivElement>;
}>;

function FlexElement(props: Props): JSX.Element {
  const flexStyles: CSSProperties = useMemo(() => {
    // gather all defined styles
    const definedStyles: CSSProperties = {
      flex: props.flex,
      flexDirection: props.direction,
      gap: props.gap,
      flexWrap: props.wrap,
      justifyContent: props.justifyContent,
      alignItems: props.alignItems,
      minHeight: props.minHeight,
      minWidth: props.minWidth,
    };

    if (props.outerContentPadding) {
      definedStyles.padding = 'var(--content-side-padding)';
    }
    // remove undefined styles
    Object.entries(definedStyles).forEach(([key, value]) => {
      if (typeof value === 'undefined') {
        delete definedStyles[key as keyof CSSProperties];
      }
    });

    return { ...definedStyles, ...props.attributes?.style };
  }, [
    props.flex,
    props.direction,
    props.gap,
    props.wrap,
    props.justifyContent,
    props.alignItems,
    props.minHeight,
    props.minWidth,
    props.outerContentPadding,
    props.attributes?.style,
  ]);

  return (
    <div
      ref={props.elementRef}
      {...{
        ...props.attributes,
        style: flexStyles,
        className: join(styles.flex, props.attributes?.className),
      }}
    >
      {props.children}
    </div>
  );
}

export { FlexElement };
