import { IPhase, IPhaseLevel, PhaseMastery } from '@hulanbv/toftennis';
import { HTMLAttributes } from 'react';
import { useModalContext } from '../../../domain/common/hooks/modal/use-modal-context.hook';
import { join } from '../../../domain/common/utilities/join.utility';
import { ConfirmModalTemplate } from '../../templates/confirm-modal-template.component';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './user-phase-level-selector-element.module.css';
import { sportBallGraphics } from '../../../domain/common/constants/sport-ball-graphics.constant';

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;
  currentLevel?: number;
  currentMastery?: PhaseMastery;
  phase: IPhase;
  onLevelChange: (
    newLevel: number,
    newMastery: PhaseMastery,
  ) => void | Promise<void>;
};

function UserPhaseLevelSelector(props: Props): JSX.Element {
  const { openModal } = useModalContext();

  const handleLevelClick = async (phaseLevel: IPhaseLevel) => {
    let newLevel = phaseLevel.level;
    let newMastery = PhaseMastery.MASTERED;

    if (props.currentLevel === newLevel) {
      if (props.currentMastery === PhaseMastery.MASTERED) {
        newLevel -= 1;
      }
      newMastery = PhaseMastery.MASTERED;
    }

    if (props.currentLevel && props.currentLevel > newLevel) {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModalTemplate
          resolve={resolve}
          content={
            <p>
              Wil je de geselecteerde gebruiker(s) terugzetten naar niveau{' '}
              <strong>{newLevel}</strong> van{' '}
              <strong>{props.phase.skill}</strong>
            </p>
          }
        />
      ));

      if (!isConfirmed) {
        return;
      }
    }

    await props.onLevelChange(newLevel, newMastery);
  };

  const { phaseLevels } = props.phase;

  return (
    <FlexElement attributes={props.attributes} direction="row" gap={25}>
      {/** Only render if all 3 phase levels are defined */}
      {phaseLevels?.length === 3 &&
        phaseLevels?.map((phaseLevel, index) => (
          <div
            key={index}
            onClick={() => handleLevelClick(phaseLevel)}
            className={join(
              styles.level,
              phaseLevel.level <= (props.currentLevel ?? 0) && styles.achieved,
              phaseLevel.level === props.currentLevel &&
                props.currentMastery === PhaseMastery.TREATED &&
                styles.treated,
            )}
            style={{
              backgroundImage:
                phaseLevel.level <= (props.currentLevel ?? 0)
                  ? `url(${sportBallGraphics[props.phase.sport]})`
                  : undefined,
            }}
          />
        ))}
    </FlexElement>
  );
}

export { UserPhaseLevelSelector };
