import { IPhase } from '@hulanbv/toftennis';
import { IHttpOptions } from 'nest-utilities-client';
import { useState, useCallback, useEffect } from 'react';
import { phaseService } from '../phase.service';

type UsePhasesHook = {
  phases: IPhase[] | null;
};

function usePhases(options?: IHttpOptions): UsePhasesHook {
  const [phases, setPhases] = useState<IPhase[] | null>(null);

  const fetchPhases = useCallback(async () => {
    const { data } = await phaseService.getAll(options);
    setPhases(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- check for stringyfied changes
  }, [JSON.stringify(options)]);

  useEffect(() => {
    fetchPhases();
  }, [fetchPhases]);

  return { phases };
}

export { usePhases };
