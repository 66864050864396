import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { ModalElement } from '../elements/modal-element/modal-element.component';

function ContextModalTemplate(): JSX.Element | null {
  const { modal } = useModalContext();
  if (!modal) {
    return null;
  }

  return <ModalElement>{modal.content}</ModalElement>;
}

export { ContextModalTemplate };
