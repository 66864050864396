import { useEffect, useState } from 'react';
import { useAuthContext } from '../domain/authentication/use-auth-context.hook';
import { routes } from '../domain/common/constants/routes.constant';
import { RouterElement } from './elements/router-element/router-element.component';

// First entry point where the application is rendered.
function Application(): JSX.Element {
  const { validate } = useAuthContext();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    validate().finally(() => setIsInitialized(true));
  }, [validate]);

  if (!isInitialized) {
    return <></>;
  }

  return <RouterElement routes={routes} />;
}

export { Application };
