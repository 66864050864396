import { PropsWithChildren } from 'react';
import { Notification } from '../../../domain/common/hooks/notification/use-notification.hook';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './notification-element.module.css';

type Props = PropsWithChildren<{
  type: Notification['type'];
}>;

function NotificationElement(props: Props): JSX.Element {
  return (
    <FlexElement attributes={{ className: styles.notification }}>
      {props.children}
    </FlexElement>
  );
}

export { NotificationElement };
