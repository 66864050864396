import { Sport } from '@hulanbv/toftennis';
import tennisSvg from '../../../assets/graphics/tennis-ball.svg';
import padelSvg from '../../../assets/graphics/padel-ball.svg';

const sportBallGraphics: Record<Sport, string> = {
  [Sport.TENNIS]: tennisSvg,
  [Sport.PADEL]: padelSvg,
  // [Sport.WHEELCHAIR_TENNIS]: tennisSvg,
};

export { sportBallGraphics };
