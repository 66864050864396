import { FormEvent, useCallback, useState } from 'react';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { InputElement } from '../elements/input-element/input-element.component';

type Props = {
  onSubmit?: (formData: FormData) => void | Promise<void>;
};

function LoginFormTemplate(props: Props): JSX.Element {
  const [isDisabled, setIsDisabled] = useState(false);
  const handleOnSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setIsDisabled(true);
      try {
        await props.onSubmit?.(new FormData(event.currentTarget));
      } catch {
        // todo: add notification
      }
      setIsDisabled(false);
    },
    [props],
  );

  return (
    <form style={{ width: '100%' }} onSubmit={handleOnSubmit}>
      <FlexElement>
        <InputElement
          attributes={{
            name: 'username',
            placeholder: 'Gebruikersnaam',
            required: true,
            disabled: isDisabled,
          }}
        />
        <InputElement
          attributes={{
            name: 'password',
            placeholder: 'Wachtwoord',
            type: 'password',
            required: true,
            disabled: isDisabled,
          }}
        />
        <ButtonElement attributes={{ type: 'submit', disabled: isDisabled }}>
          Bevestigen
        </ButtonElement>
      </FlexElement>
    </form>
  );
}

export { LoginFormTemplate };
