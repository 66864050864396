import { HTMLAttributes, PropsWithChildren } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './ellipsis-element.module.css';

type Props = PropsWithChildren<{
  attributes?: HTMLAttributes<HTMLDivElement>;
}>;

function EllipsisElement(props: Props): JSX.Element {
  return (
    <div
      {...{
        ...props.attributes,
        className: join(styles.ellipsis, props.attributes?.className),
      }}
    >
      {props.children}
    </div>
  );
}

export { EllipsisElement };
