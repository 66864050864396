import { PhaseColor, Sport } from '@hulanbv/toftennis';

const phaseColorLevelFontColors: Record<
  Sport,
  Record<PhaseColor, [string, string, string]>
> = {
  [Sport.TENNIS]: {
    [PhaseColor.RED]: [
      'var(--brand-text-light)',
      'var(--brand-text-light)',
      'var(--brand-text-light)',
    ],
    [PhaseColor.ORANGE]: [
      'var(--brand-text-light)',
      'var(--brand-text-light)',
      'var(--brand-text-light)',
    ],
    [PhaseColor.GREEN]: [
      'var(--brand-text-light)',
      'var(--brand-text-light)',
      'var(--brand-text-light)',
    ],
  },
  [Sport.PADEL]: {
    [PhaseColor.RED]: [
      'var(--brand-text-light)',
      'var(--brand-text-light)',
      'var(--brand-text-light)',
    ],
    [PhaseColor.ORANGE]: [
      'var(--brand-text-dark)',
      'var(--brand-text-light)',
      'var(--brand-text-light)',
    ],
    [PhaseColor.GREEN]: [
      'var(--brand-text-light)',
      'var(--brand-text-light)',
      'var(--brand-text-light)',
    ],
  },
  // [Sport.WHEELCHAIR_TENNIS]: {
  // [PhaseColor.RED]: [
  //   'var(--brand-text-light)',
  //   'var(--brand-text-light)',
  //   'var(--brand-text-light)',
  // ],
  // [PhaseColor.ORANGE]: [
  //   'var(--brand-text-light)',
  //   'var(--brand-text-light)',
  //   'var(--brand-text-light)',
  // ],
  // [PhaseColor.GREEN]: [
  //   'var(--brand-text-light)',
  //   'var(--brand-text-light)',
  //   'var(--brand-text-light)',
  // ],
  // },
};

export { phaseColorLevelFontColors };
