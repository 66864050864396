import { PropsWithChildren, ReactNode } from 'react';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './modal-element.module.css';

type Props = PropsWithChildren<{
  children: ReactNode | null;
}>;

function ModalElement(props: Props): JSX.Element {
  return (
    <FlexElement attributes={{ className: styles.backdrop }}>
      <div className={styles.scrollContainer}>
        <div className={styles.modal}>{props.children}</div>
      </div>
    </FlexElement>
  );
}

export { ModalElement };
